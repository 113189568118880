import React from 'react'

const OurService = () => {
  return (
    <section id='layanan-kami'>
      <div className='container container-2'>
        <div className='row justify-content-between'>
          <div className='col-md-12'>
            <h2 className='text-center'>Layanan Utama gKargo</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 d-flex justify-content-start'>
            <div className='box-layanan'>
              <span>
                <img src='img/icon-1.png' alt='icon' />
              </span>
              Shipping Service
            </div>
          </div>
          <div className='col-md-6 d-flex justify-content-start'>
            <div className='box-layanan'>
              <span>
                <img src='img/icon-2.png' alt='icon' />
              </span>
              Warehouse Service
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 d-flex justify-content-end'>
            <div className='box-layanan'>
              <span>
                <img src='img/icon-3.png' alt='icon' />
              </span>
              Trucking Service
            </div>
          </div>
          <div className='col-md-6 d-flex justify-content-end'>
            <div className='box-layanan'>
              <span>
                <img src='img/icon-4.png' alt='icon' />
              </span>
              Airline Service
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 d-flex justify-content-center'>
            <div className='box-layanan'>
              <span>
                <img src='img/icon-5.png' alt='icon' />
              </span>
              Regulated Service
            </div>
          </div>
          <div className='col-md-6 d-flex justify-content-center'>
            <div className='box-layanan'>
              <span>
                <img src='img/icon-6.png' alt='icon' />
              </span>
              Forwarder Service
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurService
