import {useEffect, useRef, useState} from 'react'

const Navbar = () => {
  const ref = useRef()
  const [isSticky, setIsSticky] = useState(false)
  const handleScroll = () => {
    if (ref.current) {
      if (window.scrollY > ref.current.offsetTop) setIsSticky(true)
      else setIsSticky(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [ref])
  return (
    <nav
      className={`navbar navbar-expand-md navbar-dark fixed-top ${
        isSticky ? 'sticky' : ''
      }`}
      id='nav'
      ref={ref}
    >
      <div className='container-fluid '>
        <div
          className='d-flex justify-content-between'
          style={{flex: '0 0 100%'}}
        >
          <a className='navbar-brand' href='#'>
            <img src='/img/logo-gkargo.png' alt='gk-logo' />
            <img
              src='/img/logo-gkargo-dark.png'
              className='dark-logo'
              alt='dark-logo'
            />
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarCollapse'
            aria-controls='navbarCollapse'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <img src='/img/ic-nav.svg' alt='nav' />
          </button>
          <div className='collapse navbar-collapse' id='navbarCollapse'>
            <ul className='navbar-nav me-auto mb-2 mb-md-0'>
              <li className='nav-item'>
                <a className='nav-link' aria-current='page' href='#banner'>
                  Home
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  aria-current='page'
                  href='#tentang-kami'
                >
                  Tentang App
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#layanan-kami'>
                  Layanan
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='#benefit-kami'
                  tabIndex='-1'
                  aria-disabled='true'
                >
                  Keuntungan
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='#aplikasi-kami'
                  tabIndex='-1'
                  aria-disabled='true'
                >
                  Aplikasi Kami
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='#kontak-kami'
                  tabIndex='-1'
                  aria-disabled='true'
                >
                  Kontak Kami
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className='nav-link btn-green'
                  href='#'
                  tabindex='-1'
                  aria-disabled='true'
                  data-bs-toggle='modal'
                  data-bs-target='#bantuan-kami'
                >
                  Bantuan
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
