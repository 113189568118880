import axios from 'axios'
import {REACT_APP_BASE_API_URL, REACT_APP_BASIC_API_KEY} from '../configs'
import {load, removeLocalSDatas} from './localStorage'
import {showError} from './toast'

const apiBearer = axios.create({
  headers: {
    'api-key': `Basic ${REACT_APP_BASIC_API_KEY}`,
    Authorization: `Bearer ${load('token')}`,
  },
  baseURL: REACT_APP_BASE_API_URL,
})

apiBearer.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const status = error.response?.status || 500
    if (error.message === 'canceled') return Promise.reject(error)
    if (status === 401 || status === 403) {
      showError('Session expired, please sign in again')
      removeLocalSDatas()
      window.location.reload()
    } else if (status !== 404) {
      let message = 'Internal Server Error'
      if (error.message) message = error.message
      if (error.response && error.response.data?.message)
        message = error.response.data?.message
      showError(message)
    }

    return Promise.reject(error)
  },
)

const apiBasic = axios.create({
  baseURL: REACT_APP_BASE_API_URL,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'api-key': `Basic ${REACT_APP_BASIC_API_KEY}`,
  },
})

export {apiBearer, apiBasic}
