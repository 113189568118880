import Layout from 'layout'
import Home from 'pages/home'
import StaticPage from 'pages/static-page'

const ROUTES = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {path: '/', element: <Home />},
      {path: '/:slug', element: <StaticPage />},
    ],
  },
]
export default ROUTES
