function Contact() {
  return (
    <section id='kontak-kami'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <img src='/img/bg-kontak.png' alt='' />
          </div>
          <div className='col-md-6'>
            <h2>Butuh Bantuan?</h2>
            <p>
              Kontak kami bila Anda membutuhkan bantuan terkait Layanan atau
              Aplikasi yang kami tawarkan diatas. Anda dapat masuk ke dalam
              halaman bantuan.
            </p>
            <a
              href='#'
              tabindex='-1'
              aria-disabled='true'
              data-bs-toggle='modal'
              data-bs-target='#bantuan-kami'
              className='btn btn-white'
            >
              Bantuan
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
