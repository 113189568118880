import React from 'react'

const Banner = () => {
  return (
    <section id='banner'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-7'>
            <h2>
              Aplikasi Layanan Agregrator Kargo Udara Terbaik di Indonesia
            </h2>
          </div>
          <div className='col-md-5'>
            <img src='img/bg-device.png' alt='device' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
