import * as DOMPurify from 'dompurify'
import {apiBearer} from 'helpers/axios'
import {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

const StaticPage = () => {
  const {slug} = useParams()
  const navigate = useNavigate()
  const [content, setContent] = useState('')
  const contentRef = useRef()
  const fethContent = async () => {
    try {
      const response = await apiBearer.get('/public/static-page/' + slug)
      const {data} = response
      if (data.code === 200) setContent(data.data.content)
    } catch (error) {
      if (error.response?.data?.code === 404)
        setContent(
          `<h1 style="text-align:center;height:50vh">Content Not Found</h1>`,
        )
      else navigate('/')
    }
    const nav = document.getElementById('nav')
    if (nav) nav.classList.add('sticky')
  }

  useEffect(() => {
    fethContent()
  }, [])

  return (
    <section className='terms-section' ref={contentRef}>
      <div className='container'>
        <div className='col-md-9 align-center'>
          <div className='main'>
            <div
              className='preview'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(content, {
                  USE_PROFILES: {html: true},
                  ADD_TAGS: ['iframe'],
                  ADD_ATTR: [
                    'allow',
                    'allowfullscreen',
                    'frameborder',
                    'scrolling',
                  ],
                }),
              }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StaticPage
