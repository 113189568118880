import AboutUs from 'components/AboutUs'
import Banner from 'components/Banner'
import Benefit from 'components/Benefit'
import OurApps from 'components/OurApps'
import OurService from 'components/OurService'
import React from 'react'

const Home = () => {
  return (
    <>
      <Banner />
      <AboutUs />
      <OurService />
      <Benefit />
      <OurApps />
    </>
  )
}

export default Home
