import Contact from 'components/Contact'
import Footer from 'components/Footer'
import Navbar from 'components/Navbar'
import React from 'react'
import {Outlet} from 'react-router-dom'

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Contact />
      <Footer />
    </>
  )
}

export default Layout
