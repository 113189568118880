export const {
  REACT_APP_PLAYSTORE_CONSIGNE_LINK,
  REACT_APP_PLAYSTORE_TRUCKING_LINK,
  REACT_APP_PLAYSTORE_DRIVER_LINK,
  REACT_APP_PLAYSTORE_WAREHOUSE_LINK,

  REACT_APP_APPSTORE_CONSIGNE_LINK,
  REACT_APP_APPSTORE_TRUCKING_LINK,
  REACT_APP_APPSTORE_DRIVER_LINK,
  REACT_APP_APPSTORE_WAREHOUSE_LINK,

  REACT_APP_WEB_PORTAL_LINK,
  REACT_APP_BASE_API_URL,
  REACT_APP_BASIC_API_KEY,
  REACT_APP_PUBLIC_URL,
} = process.env
