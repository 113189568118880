import {useRoutes} from 'react-router-dom'
import ROUTES from 'routes'
import './assets/css/bootstrap.min.css'
import './assets/css/custom.css'

function App() {
  const routes = useRoutes(ROUTES)

  return routes
}

export default App
