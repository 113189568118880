import {
  REACT_APP_PLAYSTORE_CONSIGNE_LINK,
  REACT_APP_PLAYSTORE_DRIVER_LINK,
  REACT_APP_PLAYSTORE_WAREHOUSE_LINK,
  REACT_APP_WEB_PORTAL_LINK,
} from 'configs'

function OurApps() {
  return (
    <section id='aplikasi-kami'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-6 mb-5'>
            <h2 className='text-center'>Aplikasi Layanan Kami</h2>
            <p className='text-center'>
              Berikut ada Aplikasi Mobile yang dapat Anda gunakan untuk
              menunjang kebutuhan Kargo dan Bisnis Anda
            </p>
          </div>
        </div>
        <div className='row app-kami'>
          <div className='col-md-6 d-flex justify-content-start'>
            <img src='img/bg-app-1.png' alt='' />
          </div>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-12 mb-4 d-flex justify-content-start'>
                <img src='img/logo-app-1.png' alt='' className='me-3' />
                <h2>
                  Aplikasi <br />
                  gKargo Consignee
                </h2>
              </div>
            </div>
            <div className='row d-flex justify-content-between'>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-1.png' alt='' />
                <h4>Proses pembuatan CDO</h4>
                <p>
                  Pengurusan dokumen CDO lebih mudah dengan menggunakan jasa di
                  aplikasi gKargo. Proses lebih cepat dan mudah.
                </p>
              </div>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-2.png' alt='' />
                <h4>Pemesanan Truk</h4>
                <p>
                  Sewa truk murah secara cepat, mudah, dan aman ke seluruh
                  Indonesia. Menggunakan sistem booking dan tracking.
                </p>
              </div>
            </div>
            <div className='row d-flex justify-content-between'>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-3.png' alt='' />
                <h4>Melacak Pengiriman</h4>
                <p>
                  Fitur yang berfungsi untuk mengetahui track pengiriman barang
                  dari warehouse ke lokasi penerima.
                </p>
              </div>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-4.png' alt='' />
                <h4>Monitor Saldo</h4>
                <p>
                  Pengguna dapat melakukan monitoring (inquiry) posisi saldo,
                  mutasi saldo dan topup saldo
                </p>
              </div>
            </div>
            <div className='row d-flex justify-content-between mt-4'>
              <div className='col-md-12'>
                <h4 className='title-download'>
                  Download Aplikasi gKargo Consignee
                </h4>
                <a
                  href={REACT_APP_PLAYSTORE_CONSIGNE_LINK}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src='img/google.png' alt='' />
                </a>
                {/* <a
                  href={REACT_APP_APPSTORE_CONSIGNE_LINK}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src='img/appstore.png' alt='' />
                </a> */}
                <hr className='divider-border' />
                <a
                  href={REACT_APP_WEB_PORTAL_LINK}
                  target='_blank'
                  rel='noreferrer'
                  className='btn btn-outline-green btn-login'
                >
                  Login Web Portal
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='row app-kami flex-row-reverse'>
          <div className='col-md-6 d-flex justify-content-start'>
            <img src='img/bg-app-2.png' alt='' />
          </div>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-12 mb-4 d-flex justify-content-start'>
                <img src='img/logo-app-2.png' alt='' className='me-3' />
                <h2>
                  Aplikasi <br />
                  gKargo Driver
                </h2>
              </div>
            </div>
            <div className='row d-flex justify-content-between'>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-5.png' alt='' />
                <h4>Dashboard Statistik</h4>
                <p>
                  Monitoring riwayat order atau laporan order per hari, per
                  minggu atau per bulan dan detail order serta review dari
                  pelanggan.
                </p>
              </div>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-6.png' alt='' />
                <h4>Data Booking Order</h4>
                <p>
                  Driver dapat melihat booking order yang masuk hasil assignment
                  dari pihak vendor trucking kepada driver.
                </p>
              </div>
            </div>
            <div className='row d-flex justify-content-between'>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-7.png' alt='' />
                <h4>Map dan Real Time Tracking</h4>
                <p>
                  Fitur yang berfungsi untuk mengetahui track pengiriman barang
                  dari warehouse ke lokasi penerima.
                </p>
              </div>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-8.png' alt='' />
                <h4>Scan QR Code</h4>
                <p>
                  Fitur Scan QR mempermudah pihak Driver dan juga warehouse
                  dalam pengambilan barang. Memastikan barang yang diambil
                  sesuai dengan booking request
                </p>
              </div>
            </div>
            <div className='row d-flex justify-content-between mt-4'>
              <div className='col-md-12'>
                <h4 className='title-download'>
                  Download Aplikasi gKargo Driver
                </h4>
                <a
                  href={REACT_APP_PLAYSTORE_DRIVER_LINK}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src='img/google.png' alt='' />
                </a>
                {/* <a
                  href={REACT_APP_APPSTORE_DRIVER_LINK}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src='img/appstore.png' alt='' />
                </a> */}
                {/* <hr className='divider-border' />
                <a
                  href={REACT_APP_WEB_PORTAL_LINK}
                  target='_blank'
                  rel='noreferrer'
                  className='btn btn-outline-green btn-login'
                >
                  Login Web Portal
                </a> */}
              </div>
            </div>
          </div>
        </div>

        <div className='row app-kami'>
          <div className='col-md-6 d-flex justify-content-start'>
            <img src='img/bg-app-3.png' alt='' />
          </div>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-12 mb-4 d-flex justify-content-start'>
                <img src='img/logo-app-3.png' alt='' className='me-3' />
                <h2>
                  Aplikasi <br />
                  gKargo Warehouse
                </h2>
              </div>
            </div>
            <div className='row d-flex justify-content-between'>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-5.png' alt='' />
                <h4>Dashboard Statistik</h4>
                <p>
                  Monitoring riwayat order atau laporan order per hari, per
                  minggu atau per bulan dan detail order serta review dari
                  pelanggan.
                </p>
              </div>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-6.png' alt='' />
                <h4>Proses Approval CDO</h4>
                <p>
                  Setiap request CDO yang masuk dari consignee, dapat dilakukan
                  approval via mobile app oleh staf warehouse dengan cepat dan
                  mudah.
                </p>
              </div>
            </div>
            <div className='row d-flex justify-content-between'>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-8.png' alt='' />
                <h4>Scan QR Code</h4>
                <p>
                  Fitur ini berfungsi saat driver akan melakukan pickup barang
                  di warehouse. Staf Warehouse cukup Scan QR Code untuk approval
                  pickup.
                </p>
              </div>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-9.png' alt='' />
                <h4>Data Pickup Order</h4>
                <p>
                  Menampilkan Data pickup order yang lebih mendetail dan jelas,
                  sehingga meminimalisir kesalahan pickup barang oleh driver.
                </p>
              </div>
            </div>

            <div className='row d-flex justify-content-between mt-4'>
              <div className='col-md-12'>
                <h4 className='title-download'>
                  Download Aplikasi gKargo Warehouse
                </h4>
                <a
                  href={REACT_APP_PLAYSTORE_WAREHOUSE_LINK}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src='img/google.png' alt='' />
                </a>
                <hr className='divider-border' />
                <a
                  href={REACT_APP_WEB_PORTAL_LINK}
                  target='_blank'
                  rel='noreferrer'
                  className='btn btn-outline-green btn-login'
                >
                  Login Web Portal
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='row app-kami flex-row-reverse'>
          <div className='col-md-6 d-flex justify-content-start'>
            <img src='img/bg-app-4.png' alt='' />
          </div>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-12 mb-4 d-flex justify-content-start'>
                <img src='img/logo-app-4.png' alt='' className='me-3' />
                <h2>
                  Aplikasi gKargo <br />
                  Vendor Trucking
                </h2>
              </div>
            </div>
            <div className='row d-flex justify-content-between'>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-2.png' alt='' />
                <h4>Data Booking Order</h4>
                <p>
                  Setiap request order yang mauk dari pihak consignee akan
                  langsung muncul pada data manajemen order
                </p>
              </div>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-11.png' alt='' />
                <h4>Manajemen Driver</h4>
                <p>
                  Fitur manajemen driver berfungsi mengatur data driver dan
                  mengassign driver sesuai request order dari consignee.
                </p>
              </div>
            </div>
            <div className='row d-flex justify-content-between'>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-12.png' alt='' />
                <h4>Tarif Kendaraan</h4>
                <p>
                  Anda dapat mengatur tarif kendaraan sesuai dengan keinginan
                  Anda dengan mudah dan cepat
                </p>
              </div>
              <div className='col-md-6 text-start'>
                <img src='img/icon-kargo-13.png' alt='' />
                <h4>Dashboard Statistik</h4>
                <p>
                  Monitoring riwayat order dan keuangan yang telah masuk kedalam
                  Aplikasi
                </p>
              </div>
            </div>
            <div className='row d-flex justify-content-between mt-4'>
              <div className='col-md-8'>
                {/* <h4 className='title-download'>
                  Download Aplikasi gKargo Vendor
                </h4>
                <a
                  href={REACT_APP_PLAYSTORE_TRUCKING_LINK}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src='img/google.png' alt='' />
                </a> */}
                {/* <a
                  href={REACT_APP_APPSTORE_TRUCKING_LINK}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src='img/appstore.png' alt='' />
                </a> */}
              </div>
              <div className='col position-relative'>
                {/* <hr className='divider-border' /> */}
                <a
                  href={REACT_APP_WEB_PORTAL_LINK}
                  target='_blank'
                  rel='noreferrer'
                  className='btn btn-outline-green btn-login'
                >
                  Login Web Portal
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurApps
