import React from 'react'

const AboutUs = () => {
  return (
    <section id='tentang-kami'>
      <div className='container container-2'>
        <div className='row justify-content-between'>
          <div className='col-md-6'>
            <h2>Tentang gKargo</h2>
            <h3>
              Kami menyediakan jasa pengiriman dengan pengalaman lebih dari 10
              tahun pengalaman dan pengiriman total 1 juta barang
            </h3>
          </div>
          <div className='col-md-5'>
            <p>
              gKargo adalah sebuah jasa pelayanan menyeluruh untuk penanganan
              kargo udara di Indonesia yang melibatkan banyak pemangku
              kepentingan (stake holder) di bandara yang ada di Indonesia.
              gKargo berintegrasi dengan NLE Bea Cukai Indonesia sehingga
              diharapkan kegiatan kargo di setiap bandara Indonesia menjadi
              lebih mudah dan cepat dan efisien serta mendapatkan informasi
              lebih jelas dan transparan.
            </p>
          </div>
        </div>
        <div className='row justify-content-between counter-box' style={{'display': 'none'}}>
          <div className='col-md-3 d-flex justify-content-between'>
            <h6>Projek</h6>
            <h2>300K +</h2>
          </div>
          <div className='col-md-1 d-flex justify-content-center'>
            <hr />
          </div>
          <div className='col-md-3 d-flex justify-content-between'>
            <h6>Klien</h6>
            <h2>700 +</h2>
          </div>
          <div className='col-md-1 d-flex justify-content-center'>
            <hr />
          </div>
          <div className='col-md-3 d-flex justify-content-between'>
            <h6>Tim</h6>
            <h2>550 +</h2>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
