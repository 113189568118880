import React from 'react'

function Benefit() {
  return (
    <section id="benefit-kami">
    <div className="container container-2">
      <div className="row justify-content-between">
        <div className="col-md-5">
          <h2>
            Mengapa Anda harus menggunakan Aplikasi Website & Mobile kami,
            berikut keunggulan yang kami tawarkan
          </h2>
        </div>
        <div className="col-md-6">
          <ul>
            <li>
              <img src="img/benefit-1.png" alt='benefit'/> Aplikasi tersedia untuk
              Consignee, Warehouse, Driver dan Vendor Trucking
            </li>
            <li>
              <img src="img/benefit-2.png" alt='benefit'/> Pengurusan Dokumen & Booking
              Truk dengan mudah & Cepat
            </li>
            <li>
              <img src="img/benefit-3.png" alt='benefit'/> Dukungan layanan berbasis
              teknologi informasi kepada pengguna
            </li>
            <li>
              <img src="img/benefit-4.png" alt='benefit'/> Orientasi kepuasaan pelanggan,
              Akuntabel, Responsif,
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Benefit