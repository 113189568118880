const Footer = () => {
  return (
    <>
      <footer>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <a className='navbar-brand' href='#'>
                <img src='/img/logo-gkargo.png' alt='' />
              </a>
              <p>
                PT Selaras Abadi KOI
                <br />
                Jl. Utama Kencana No.118, Pondok Aren, Kota Tangerang Selatan,
                Banten 15225
                <br />
                Email: info@gkargo.id | helpdesk@gkargo.id | 0812-2323-8314 |
                0812-2323-8315
              </p>
            </div>
            <div className='col-md-5'>
              <h6>gKargo – Gerbang Kargo | Cargo Gateway</h6>
              <p>Jasa Pelayanan Agregator Kargo Udara di Indonesia untuk</p>
              <ul>
                <li>Pengiriman</li>
                <li>Reservasi Agen</li>
                <li>Armada Truk</li>
                <li>Penanganan Kargo</li>
              </ul>
            </div>
            <div className='col-md-3'>
              <h6 className='mb-4'>Lebih Dekat Dengan Kami</h6>
              <a href='/'>
                <img src='/img/ic-fb.png' alt='' />
              </a>
              <a href='/'>
                <img src='/img/ic-ig.png' alt='' />
              </a>
              <a href='/'>
                <img src='/img/ic-in.png' alt='' />
              </a>
              <p className='mt-4'>www.gkargo.id</p>
            </div>
          </div>
        </div>
      </footer>

      <div className='modal popup-bantuan' id='bantuan-kami' tabindex='-1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='row'>
              <div className='col-md-6 img-side'>
                <div className='img-bantuan'>
                  <img src='img/bg-kontak.png' alt='' />
                </div>
                <div className='teks-image'>
                  <h3>Butuh Bantuan</h3>
                  <p>Silahkan hubungi kami</p>
                </div>
              </div>
              <div className='col-md-6 teks-side'>
                <div className='teks-kontak'>
                  <p>Silahkan hubungi Nomor Whatsapp Helpdesk berikut ini:</p>
                  <div className='kontak-list'>
                    <button>
                      <div className='row'>
                        <div className='col-3 img-phone'>
                          <img src='/img/ic-phone.png' alt='' />
                        </div>
                        <div className='col-9 no-helpdesk'>
                          <p>Helpdesk 1</p>
                          <a
                            target='_blank'
                            rel='noreferrer'
                            href='https://api.whatsapp.com/send/?phone=6281223238314&text&type=phone_number&app_absent=0'
                          >
                            081223238314
                          </a>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className='kontak-list'>
                    <button>
                      <div className='row'>
                        <div className='col-3 img-phone'>
                          <img src='/img/ic-phone.png' alt='' />
                        </div>
                        <div className='col-9 no-helpdesk'>
                          <p>Helpdesk 2</p>
                          <span></span>
                          <a
                            target='_blank'
                            rel='noreferrer'
                            href='https://api.whatsapp.com/send/?phone=6281223238315&text&type=phone_number&app_absent=0'
                          >
                            081223238315
                          </a>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
